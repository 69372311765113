import React from "react";
import { useQuery, gql } from "@apollo/client";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Loader from "../components/Loader";
import RuntimeBlogImage from "../components/RuntimeBlogImage";
import RelatedPosts from "../components/RelatedPosts";
import { Seo } from "./SEO";

const GET_ARTICLE = gql`
  query ($handle: String!) {
    blogs(first: 250, reverse: true) {
      edges {
        node {
          id
          title
          handle
          articleByHandle(handle: $handle) {
            title
            contentHtml
            excerpt
            publishedAt
            image {
              originalSrc
              url
              altText
              width
              height
            }
            authorV2 {
              name
            }
            handle
          }
        }
      }
    }
  }
`;

const SingleBlog = ({handle}) => {

  const imgData = useStaticQuery(graphql`
    query {
      blogFluidImage: file(relativePath: { eq: "blog_icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
    }
  `);
  const blogIcon = getImage(imgData.blogFluidImage);

  const { data, loading } = useQuery(GET_ARTICLE, {
    variables: { handle: handle },
    onError: (error) => {
        console.log(error.message)
    }
  });
  let blogDetail = data?.blogs.edges[0].node.articleByHandle;

  return (

      <main>
        {loading ? (
          <Loader />
        ) : (
        
          <div className="container mx-auto">
            {blogDetail === null ? (
              <div>
                <p>No information in blog</p>
              </div>
            ) : (
              <div> 
                 <Seo title={blogDetail.title}/>
                <div className="pt-8 pb-16 lg:pt-16 lg:pb-10 bg-white">
                  <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                    <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue">
                      <header className="mb-4 lg:mb-6 not-format">
                        <address className="flex items-center mb-6 not-italic">
                          <div className="inline-flex items-center mr-3 text-sm text-gray-900">
                            <GatsbyImage
                              image={blogIcon}
                              alt="author"
                              className="w-10 h-10 rounded-full overflow-hidden mr-4"
                            />
                            <div>
                              <p className="text-xl font-bold text-gray-900">
                                {blogDetail?.authorV2.name}
                              </p>

                              <p className="text-base font-light text-gray-500">
                                {blogDetail?.publishedAt.substr(0, 10)}
                              </p>
                            </div>
                          </div>
                        </address>
                        <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">
                          {blogDetail?.title}
                        </h1>
                        {blogDetail.image &&
                        <RuntimeBlogImage
                          storefrontProduct={blogDetail?.image}
                        />
                        }
                      </header>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: blogDetail?.contentHtml,
                        }}
                      ></p>
                    </article>
                  </div>
                </div>
              </div>
            )}
            <RelatedPosts articleId={handle} />
          </div>
        )}
      </main>
  );
};

export default SingleBlog;
