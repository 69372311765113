import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import RuntimeDetailImage from "./RuntimeDetailImage";
import { shuffle } from "../utils/shuffleAlgorithm";

const RELATED_POSTS = gql`
  query RELATED_POSTS($sortKey: ArticleSortKeys) {
    articles(first: 250, sortKey: $sortKey) {
      edges {
        node {
          handle
          image {
            width
            height
            originalSrc
            url
            altText
          }
          publishedAt
          authorV2 {
            name
          }
          id
          title
          excerpt
        }
      }
    }
  }
`;

const RelatedPosts = ({ articleId }) => {

  const imgData = useStaticQuery(graphql`
    query {
      bannerFluidImage: file(relativePath: { eq: "placeholder.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
    }
  `);
  const image = getImage(imgData.bannerFluidImage);

  const { data, loading } = useQuery(RELATED_POSTS, {
    // fetchPolicy: "no-cache",
    onError: (error) => {
      console.log(error.message);
    },
  });
  const similar = [];
  if (!loading) {
    data?.articles.edges.forEach((node) => {
    if( node.node.handle !== articleId)
        similar.push(node.node);
    });
    shuffle(similar);
  }

  return (
  <>
    <aside aria-label="Related articles" className="py-8 lg:py-24 bg-gray-200">
      <div className="px-4 mx-auto max-w-screen-xl">
        {similar.length > 0 ? (
          <h3 className="mb-8 text-2xl font-bold text-gray-900">
            Read more
          </h3>
        ) : (
          <h3 className="mb-8 text-2xl text-center font-bold text-gray-900">
            No similar articles are available
          </h3>
        )}
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          {similar &&
            similar.slice(0, 4).map((article) => (
              <article className=" max-w-full sm:max-w-xs" key={article.id}>
                {article.image && (
                <Link to={`/blog/${article.handle}`}>
                  <RuntimeDetailImage
                    className="rounded-t-lg"
                    storefrontProduct={article.image}
                    alt="Image"
                  /> 
                  </Link>
                )}
               
                {!article.image && (
                <Link to={`/blog/${article.handle}`}>
                  <GatsbyImage
                    className="w-full max-w-full h-[250px]"
                    image={image}
                    alt="Placeholder"
                  />
                  </Link>
                )}
                <h4 className="my-2 text-xl font-bold leading-tight text-gray-900">
                  <Link to={`/catalog/${article.handle}`}>{article.title}</Link>
                </h4>
                <p className="mb-4 font-light text-gray-900">
                  {article.excerpt}
                </p>
                <Link
                  to={`/blog/${article.handle}`}
                  className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 hover:no-underline"
                >
                  View
                </Link>
              </article>
            ))}
        </div>
      </div>
    </aside>
    </>
  )
};

export default RelatedPosts;
