import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";

const RuntimeBlogImage = ({ storefrontProduct }) => {
  const gatsbyImageData = getShopifyImage({
    image: storefrontProduct,
  });

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt={storefrontProduct.altText ? storefrontProduct.altText : ""}
      className="w-full object-center"
    />
  );
};

export default RuntimeBlogImage;
