import React from "react";
import Layout from "../../components/Layout";
import SingleBlog from "../../components/SingleBlog";
const isBrowser = typeof window !== "undefined";

const BlogHandle = () => {

      let handle;
      if (isBrowser) {
        handle = window.location.pathname.substring(6);
      }



  return (
    <Layout>
      <SingleBlog handle={handle}/>
    </Layout>
  );
};

export default BlogHandle;
